.SkinConditionsRate {
  text-align: center;

  --vertical-slider-color: #004987;
}

.SkinConditionsRate .slider.vertical {
  display: flex;
  height: 276px;
  margin: auto;
  margin-top: 54px;
}

.SkinConditionsRate .labels {
  color: var(--vertical-slider-color);
  display: flex;
  justify-content: center ;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 2 1 auto;
  justify-content: space-between;
  width: 140px;

  --labels-vertical-shift: -20px;
}

.SkinConditionsRate .labels .option {
  display: flex;
  font-size: 20px;
  line-height: 24px;
  margin: 0 10px;
  white-space: initial;
  word-spacing: 10px;
}

.SkinConditionsRate .labels .selected {
  margin: 0 20px;
  font-family: "acumin-pro";
  font-size: 22px;
  line-height: 26px;
  color: #13A9AF;
}

.SkinConditionsRate .left {
  margin-top: var(--labels-vertical-shift);
  text-align: right;
}
.SkinConditionsRate .left div {
  display: flex;
  align-content: center;
  justify-content: center;
}

.SkinConditionsRate .range {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}

.SkinConditionsRate .range::after {
  content: "";
  display: flex;
  height: 100%;
  width: 4px;
  background-color: #1C4983;
  z-index: 0;
}
.SkinConditionsRate .range::after {
  margin-left: -3px;
}
.SkinConditionsRate .right {
  margin-bottom: var(--labels-vertical-shift);
  text-align: left;
}

.SkinConditionsRate .preload {
  font-family: "acumin-pro";
  color: rgba(1, 1, 1, 0)
}

.SkinConditionsRate .preload-italic {
  font-family: "acumin-pro-italic";
  color: rgba(1, 1, 1, 0)
}
