h1.Logo,
span.Logo {
  color: #0070ce;
  font-size: 43px;
  font-weight: var(--light-font-weight);
  letter-spacing: 0.86px;
  line-height: 43px;
  margin: 0;
  text-align: center;
}
span.Logo {
  line-height: normal;
}

h1.Logo span,
span.Logo span {
  font-family: "acumin-pro-italic";
  font-weight: var(--default-font-weight);
}

img.Logo {
  width: 216px;
}

.Logo ~ h2 {
  font-size: 18px;
  font-weight: var(--light-font-weight);
  letter-spacing: 0.36px;
  margin: 10px 0;
}
