.Content {
  background-color: var(--color-welcome-background);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;

  --content-width: 302px;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .Content {
    min-height: -webkit-fill-available;
  }
}

.Content.scrollable {
  height: auto;
}

.Content-elements {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  width: var(--content-width);
}

.blurred {
  filter: blur(15px) brightness(0.8);
  opacity: 0.7;
}
