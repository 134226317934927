.Processing {
  align-items: center;
  color: #004987;
  display: flex;
  flex-direction: column;
  font-size: 23px;
  justify-content: center;
  line-height: 28px;
  margin: auto;
}

.Processing .chart {
  margin-bottom: 38px;
  width: 220px;
}
