.SkinConditions {
  text-align: center;
  --turquise: #13A9AF;
}


.SkinConditions .options {
  margin: auto 0;
}

.SkinConditions .skinConditionsOption {
  align-items: center;
  border-radius: 20px;
  border: 1px solid #1C4983;
  box-shadow: 0 1px rgba(0, 0, 0, 0.16);
  display: flex;
  height: 40px;
  margin-bottom: 27px;
}

.SkinConditions .skinConditionsOption.selected {
  background: var(--turquise);
  color: white;
  border-color: var(--turquise);
}

.SkinConditions .skinConditionsOption .form-check-input {
  margin: 0;
  visibility: hidden;
  width: 1px;
}

.SkinConditions .skinConditionsOption .form-check-label {
  color: #004987;
  margin: auto;
  order: -1;
  width: 100%;
  font-size: 20px;
}

.SkinConditions .skinConditionsOption.selected .form-check-label {
  color: white;
}