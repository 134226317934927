.Radar {
  display: flex;
  align-items: center;
  /* flex-grow: 2; */
  justify-content: center;
  margin-right: -8px;

  width: 190px;
  height: 100%;
  overflow-y: overlay;
}

.Radar.full {
  color: #3E4040;
  font-size: 16px;
  margin-left: 0;
  width: 100%;
}
