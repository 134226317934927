.Results {
  color: #0070CE;
  display: flex;
  flex-direction: column;
}

.Results .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -19px;
  margin-right: -19px;
}

.Results .title {
  font-size: 24px;
  font-weight: var(--light-font-weight);
  margin-bottom: 16px;
  margin-top: 33px;
}

.Results .Verdict {
  margin-bottom: 26px;
}

.Results .download {
  margin-bottom: 27px;
}

.Results .top3text {
  font-family: "acumin-pro";
  font-size: 20px;
  line-height: 22px;
  margin: auto;
  margin-bottom: 12px;
}

.Results .Complaints {
  margin-bottom: 44px;
}

.Results .TextButton.to-products {
  margin: 0 40px 34px 40px;
}