.Welcomer {
  align-items: center;
  animation: fadeIn 0.7s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Welcomer .chart {
  margin-bottom: 38px;
  width: 220px;
}

.Welcomer .chart canvas {
  animation: radar-spin infinite 20s linear;
}
.Welcomer .chart img {
  /* animation: radar-spin infinite 20s linear; */
  animation: none;
}

@keyframes radar-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
