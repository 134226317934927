.SkinType {
  color: #004987;

  --turquise: #13A9AF;
}

.SkinType .options {
  margin: auto 0;
}

.SkinType .skinTypeOption {
  align-items: center;
  border-radius: 20px;
  border: 1px solid #1C4983;
  box-shadow: 0 1px rgba(0, 0, 0, 0.16);
  display: flex;
  height: 40px;
  margin-bottom: 27px;
}

.SkinType .skinTypeOption.selected {
  background: var(--turquise);
  color: white;
  border-color: var(--turquise);
}

.SkinType .skinTypeOption .form-check-input {
  margin: 0;
  visibility: hidden;
  width: 1px;
}

.SkinType .skinTypeOption .form-check-label {
  margin: auto;
  order: -1;
  width: 100%;
  font-size: 20px;
}
