.Complaint {
  align-items: center;
  border-radius: 10px;
  border: none;
  color: white;
  display: flex;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 11px;
  margin-left: 15px;
  min-height: 29px;
  padding: 4px 8px;
  padding-left: 20px;
  text-indent: -12px; 
}

.Complaint.full {
  color: #3E4040;
  font-size: 16px;
  margin-left: 0;
}

.Complaint.full:not(.top3) {
  margin: 0;
}
