.Progress {
  display: flex;

  --bs-progress-height: 7px;
}

.Progress .bar {
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: var(--color-progress-bar);
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  background: white;
  flex-grow: 1;
  border: 1px solid rgba(46, 112, 199, 1);
}

.Progress .label {
  color: rgba(0, 112, 206, 1);
  font-size: 12px;
  line-height: calc(var(--bs-progress-height) + 2px);
  width: 30px;
}

.Progress .progress-bar {
  background-color: var(--bs-progress-bar-bg);
  color: var(--bs-progress-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  transition: var(--bs-progress-bar-transition);
  white-space: nowrap;
}
