.Slide {
  animation: fadeIn 0.5s;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: 0.18pt;
  line-height: 20px;
  text-align: center;
}
