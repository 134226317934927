.RangeScale {
  display: flex;
  height: 30px;
  justify-content: space-between;
  width: 100%;
}

.RangeScale option {
  color: #004987;
  font-family: "acumin-pro";
  font-size: 24px;
  line-height: 27px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.RangeScale .hidden {
  visibility: hidden;
}

.pull-left {
  text-align: left;
}
.pull-right {
  text-align: right;
}