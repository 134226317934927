.VisibleSkinCondition {
  text-align: center;
  padding-top: 22px;
  --turquise: #13A9AF;
}
.VisibleSkinCondition p {
  margin-top: 0;
  margin-bottom: 20px;
}

.VisibleSkinCondition .form-range {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}