.BasicInfo {
  text-align: center;
}

.BasicInfo .input-text {
  font-size: 20px;
  font-weight: 700;
  color: rgba(19, 169, 175, 1);
  text-align: center;
  text-transform: uppercase;
}

.BasicInfo .input-area {
  min-height: 40px;
  border: 1px solid #1C4983;
  border-radius: 20px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.16);
  background: white;
}

.BasicInfo input,
.BasicInfo .SexAccordion {
  margin-bottom: 28px;
}
.BasicInfo input:focus-visible {
  outline-color: rgba(19, 169, 175, 1);
}

.BasicInfo p {
  margin-bottom: 55px;
}

.BasicInfo input::placeholder {
  color: rgba(19, 169, 175, 1);
}

.BasicInfo input.with-content {
  background: rgba(19, 169, 175, 1);
  color: white;
  border: none;
}