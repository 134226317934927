.Complaints {
  height: 215px;
}

.Complaints .rating{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  justify-content: center;
  text-align: left;
}


.Complaints .rating .Complaint:nth-child(3) {
  margin-bottom: 0;
}
.Complaints.expanded .rating .Complaint:nth-child(1),
.Complaints.expanded .rating .Complaint:nth-child(2),
.Complaints.expanded .rating .Complaint:nth-child(3) {
  color: white;
}
.Complaints.expanded .rating .Complaint:nth-child(3) {
  margin-bottom: 11px;
}
.Complaints.expanded .Radar {
  margin-top: -20px;
  margin-bottom: 20px;
  order: 0;
  height: auto;
  width: 100%;
}
.Complaints .switcher .Button {
  margin-top: 10px;
  margin-right: 10px;
}

.Complaints.expanded {
  height: auto;
  flex-direction: column;
}
.Complaints.expanded .switcher {
  order: -1;
  align-self: flex-end;
}
.Complaints.expanded .rating {
  order: 1;
  height: auto;
  width: 265px;
  margin: auto;
}