.Slider .label {
  font-size: 20px;
  line-height: 24px;
  color: #0070ce;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 9px;
  height: 30px;
  background: #daf1f9 url("../../../assets/info.svg") no-repeat left 7px center;
  background-size: 17px;
  border-radius: 15px;
}

.Slider .range {
  margin-bottom: 16px;
}

.Slider .rcslider {
  padding-top: 18px;
}

.Slider .rcslider .rc-slider-dot {
  border: none;
  width: 7px;
  height: 7px;
  background: #004987;
}

.Slider .rcslider .rc-slider-track,
.Slider .rcslider .rc-slider-rail {
  margin-top: 1px;
  height: 3px;
  background: #1c4983;
}

.Slider .rcslider .rc-slider-handle {
  width: 40px;
  height: 40px;
  margin-top: -18px;
  border: 2px solid white;
  box-shadow: none;
  background: #13a9af;
  opacity: 1;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.rc-slider-handle::after {
  content: attr(aria-valuenow);
  color: white;
  padding-bottom: 2px;
  margin: auto;
}

.Slider .rcslider .rc-slider-handle-dragging {
  border: none !important;
  box-shadow: none !important;
}
