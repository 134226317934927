.SkinIrritationFactors1 {
  text-align: center;
  padding-top: 22px;
  --turquise: #13A9AF;
}

.SkinIrritationFactors1 p {
  margin-top: 0;
  margin-bottom: 20px;
}
