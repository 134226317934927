body {
  --color-default-background: #FFFFFF;
  --color-defaut-dark-font: #3E4040;

  /* Welcome screen */
  --color-welcome-background: #D6EFF7;
  /* --color-welcome-background: #E5F5FB; */

  /* Info screen */
  --color-background: rgba(0, 112, 206, 0.7);

  /* Slides */
  --color-progress-bar: #2E70C7;

  /* default values */
  color: var(--color-defaut-dark-font);
}
