.Survey {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Survey.slide {
  flex-basis: 100%;
  justify-content: space-between;
  margin: 0;
}
