.VerticalSlider .rcslider {
  height: 272px;
  margin: 0 auto;
}

.VerticalSlider .rcslider .rc-slider-dot {
  background: #004987;
  border: none;
  height: 7px;
  width: 7px;
}

.VerticalSlider .rcslider .rc-slider-track,
.VerticalSlider .rcslider .rc-slider-rail {
  background: #1c4983;
  margin-left: 2px;
  width: 3px;
}

.VerticalSlider .rcslider .rc-slider-handle {
  align-content: center;
  background: #13a9af;
  border: 2px solid white;
  box-shadow: none;
  display: flex;
  font-size: 26px;
  height: 40px;
  justify-content: center;
  margin-left: -17px;
  opacity: 1;
  width: 1px;
}

.VerticalSlider.touched .rcslider .rc-slider-handle {
  width: 40px;
}

.VerticalSlider .rcslider .rc-slider-handle::after {
  color: white;
  content: attr(aria-valuenow);
  padding-bottom: 2px;
  margin: auto;
}

.VerticalSlider .rcslider.no-markers .rc-slider-handle::after {
  content: none;
}

.VerticalSlider .rcslider .rc-slider-handle-dragging {
  border: none !important;
  box-shadow: none !important;
}

.VerticalSlider .rcslider .rc-slider-mark {
  left: -30px;
}

.VerticalSlider .rcslider .rc-slider-mark-text {
  color: #004987;
  font-family: "acumin-pro";
  font-size: 24px;
  line-height: 27px;
}
