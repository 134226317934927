.Cross {
  --close-size: 24px;
  color: #1c4983;
  display: block;
  font-size: var(--close-size);
  line-height: var(--close-size);
  margin-top: 5px;
  margin-right: 8px;
}

.Cross.plus {
  transform: rotate(45deg);
}
.Cross.close {
  transform: rotate(0);
}
