.Products {
  color: #0070CE;
  display: flex;
  flex-direction: column;
}

.Products .wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -19px;
  margin-right: -19px;
}

.Products .logo {
  margin-top: 25px;
  margin-bottom: 20px;
}

.Products h4 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 14px;
}

.Products .description {
  color: #3E4040;
  font-size: 18px;
  line-height: 1.1;
  margin-bottom: 26px;
  margin-top: 0;
  padding: 0 18px;
}

.Products .description span {
  color: #1C4883;
  font-family: "acumin-pro";
}

.Products .suggestion {
  align-items: center;
  border: 1px solid #1C4883;
  margin-bottom: 15px;
  padding: 16px;;
}

.Products .suggestion .text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}
.Products .suggestion h5 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.Products .suggestion .product-image {
  border: none;
  margin-left: 10px;
}

.Products .suggestion .complaints {
  border-top: 1px solid #1C4883;

}
.Products .suggestion .complaint {
  border-bottom: 1px solid #1C4883;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 3px;
  margin-top: 3px;
  padding: 0 5px;
}
.Products .suggestion .complaint .complaint-name {
  color: #004987;
  flex-grow: 2;
  font-size: 12px;
  line-height: 1.4;
  padding-left: 5px;
  text-align: left;
}
.Products .suggestion .complaint .arrow {
  padding-top: 2px;
}
.Products .suggestion .complaint .complaint-decrease {
  font-family: "acumin-pro";
  font-size: 16px;
  line-height: 1.4;
}

.Products .btn {
  font-size: 18px;
  margin-bottom: 20px;
  width: 258px;
}