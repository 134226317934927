.Verdict .rating {
  font-size: 81px;
  letter-spacing: 0.81px;
  line-height: 1.1;
  margin-left: 10px;
  margin-top: -5px
}
.Verdict .description {
  align-items: center;
  display: flex;
  flex-grow: 2;
  font-family: "acumin-pro";
  font-size: 25px;
  line-height: 1.2;
  padding-left: 15px;
  text-align: left;
}
.Verdict .info .Button {
  margin-right: 10px;
  margin-top: 10px;
}
