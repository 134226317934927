.SexAccordion {
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.SexAccordion.with-content:not(.open) {
  background: rgba(19, 169, 175, 1) !important;
  border: none !important;
}

.SexAccordion > .card-header + .list-group,
.SexAccordion > .list-group + .card-footer {
  border-top: 0;
}

.SexAccordion .card-header {
  margin-bottom: 0;
}

.SexAccordion .collapse.collapsed {
  display: none;
}
.SexAccordion .card-body {
  flex: 1 1 auto;
}
.SexAccordion .list-group {
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 8px;
  --bs-list-group-item-padding-y: 8px;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}
.SexAccordion ul.list-group {
  margin: 0;
}
.SexAccordion .list-group-item {
  align-content: center;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  text-decoration: none;
  border-top: 1px solid #1C4983;
  align-self: center;
  width: 80%;
}

.SexAccordion .list-group-item + .list-group-item.active {
  /* margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width); */
}
.SexAccordion .btn {
  --bs-btn-padding-x: 8px;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-line-height: 1.5;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: none;
  background-color: var(--bs-btn-bg);
  width: 90%;
}

.SexAccordion.with-content:not(.open) .btn {
  color: white;
}

.SexAccordion .card-header .glyphicon-menu-down::before { 
  position: absolute;
  margin-left: -17px;
  margin-top: 2px;
  display: inline-block;
  content: ""; 
  width: 16px;
  height: 40px;

  background: rgba(255, 255, 255, 0) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%2313A9AF" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"/></svg>') no-repeat;
  background-position: center top 50%;
  transform: rotate(180deg);
}
.SexAccordion .card-header.open .glyphicon-menu-down::before { 
  transform: rotate(0);
}