.Info {
  animation: fadeIn 0.125s;
  background-color: var(--color-background);
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
}

.Info-content {
  height: 75%;
  margin: 0 54px;
  overflow-y: auto;
}

.Info-content > :first-child {
  margin-top: 88px;
}

.Info-content .Logo {
  color: white;
}

.Info-content .Logo ~ .title {
  margin: 0;
}

.Info-content .title {
  font-size: 24px;
  font-weight: var(--light-font-weight);
  line-height: 1.2;
  margin-bottom: 0;
  white-space: pre-line;
}

.Info-content .paragraph {
  font-size: 18px;
  font-weight: var(--light-font-weight);
  letter-spacing: 0.18px;
  line-height: 20px;
  margin-top: 18px;
  white-space: pre-line;
}

.Info .close {
  margin-top: auto;
  margin-bottom: 80px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
