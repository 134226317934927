.TextButton {
  background: #0070CE;
  border-radius: 23px;
  border: none;
  color: white;
  display: flex;
  font-family: "acumin-pro-400";
  font-size: 18px;
  height: 45px;
  justify-content: center;
  line-height: 26px;
  text-align: center;
}

.TextButton:hover {
  background-color: #004987 !important;
}

.TextButton.link {
  align-items: center;
  cursor: default;
  text-decoration: none;
}